import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "../../../assets/scss/plugins/_slick.scss"
import "./blockFeeds.scss"
import { useIntl } from "gatsby-plugin-react-intl"
import { ElfsightWidget } from 'react-elfsight-widget'
import {graphql, useStaticQuery} from "gatsby"

const BlockFeeds = ({ locale }) => {
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    query blockContentBlockInstagram {
      blkInstagramEn: blockContentBlockInstagram(langcode: {eq: "en"}) {
        block_instagram_caption
        block_instagram_title
      }
      blkInstagramFr: blockContentBlockInstagram(langcode: {eq: "fr"}) {
        block_instagram_caption
        block_instagram_title
      }
    }
  `)

  // Get Instagram data by locale

  let dataInstagram
  switch (locale) {
    case "fr":
      if (data.blkInstagramFr) {
        dataInstagram = data.blkInstagramFr
      }
      break
    default:
      if (data.blkInstagramEn) {
        dataInstagram = data.blkInstagramEn
      }
  }

  return (
    <>
      <section className="blk-feeds">
        <Container className="container-wide">
          <Row>
            <Col lg={12}>
              <h2 className="eapps-instagram-feed-title">
                {dataInstagram.block_instagram_title}
              </h2>
              <span className="caption">
                <span>{dataInstagram.block_instagram_caption}</span>
              </span>
              <ElfsightWidget widgetID="187b15a1-d8e0-486f-866c-d7bc0ec613ca" />
              {/*<iframe*/}
              {/*  id="elfsight-if"*/}
              {/*  onLoad={() => window.iFrameResize({ log: false }, '#elfsight-if')}*/}
              {/*  src="https://sandbox.elfsightcdn.com/187b15a1-d8e0-486f-866c-d7bc0ec613ca"*/}
              {/*  frameBorder="0">*/}
              {/*</iframe>*/}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default BlockFeeds
