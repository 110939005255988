import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ReactPlayer from "react-player/lazy"

const BlockVideo = ({ video, otherClass }) => {
  return (
    <section className={"blk-video " + otherClass}>
      <Container>
        <Row>
          <Col>
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={video}
                width="100%"
                height="100%"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BlockVideo
