import React, {lazy, Suspense, useEffect, useState} from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import {debounce, getCarouselImageOG} from "../components/functions"
import "../components/campaign/campaign.scss"
import BlockFeeds from "../components/common/blockFeeds/blockFeeds"
import RequestQuote from "../components/common/forms/requestQuote"
import BlockVideo from "../components/common/blockVideo"

export default function Experience({ data: { nodeCampaign }, pageContext }) {
  const MainBannerImage = lazy(() =>
    import("../components/common/mainBanner/mainBannerImage")
  )
  const PageIntro = lazy(() =>
    import("../components/common/pageIntro/pageIntro")
  )
  const BlockOffers = lazy(() =>
    import("../components/campaign/blockOffers/blockOffers")
  )

  const isSSR = typeof window === "undefined"
  const {
    title,
    field_seo,
    relationships: {
      campaign_carousel: {
        relationships: { carousel_highlight_item: carouselMea },
      },
      campaign_introduction: pageIntro,
      campaign_block_campaign_info: campaignInfo,
      campaign_block_campaign_offers: offers,
      campaign_block_experiences: experiences,
      campaign_slider: slider,
      campaign_campaign_text_block: offerText,
      campaign_video: video,
    },
  } = nodeCampaign

  const intl = useIntl()

  const carouselFiltered = carouselMea.filter(
    carousel => carousel.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  const offerItems = offers?.relationships?.block_campaign_offers_items
    ? offers.relationships.block_campaign_offers_items
    : []

  let locale
  locale = intl.locale

  return (
    <Layout pageContext={pageContext} pageTitle={title} pageClass="pageCampaign">
      <Seo data={seoData} pageContext={pageContext} />
      {!isSSR && (
        <Suspense fallback="loading.... ">
          {carouselFiltered.length > 0 && (
            <MainBannerImage items={carouselFiltered} />
          )}
          {pageIntro && <PageIntro slice={pageIntro} />}
          <BlockOffers
            info={campaignInfo}
            items={offerItems}
            slider={slider}
            text={offerText}
          />
          {video && <BlockVideo otherClass="blk-spacing" video={video.field_media_oembed_video} />}
        </Suspense>
      )}
      <RequestQuote />
      <BlockFeeds locale={locale} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    nodeCampaign(id: { eq: $id }) {
      ...campaignFields
    }
  }
`
